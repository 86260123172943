.content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw
}

.home {
    /* margin: 5em 0 0 13em; */
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.blogPreview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #efefef;
    margin-top: 2em;
}

.blogTitle {
    margin-left: .5em;

    text-align: center;
    font-size: clamp(2rem, 0.9153rem + 1.6368vw, 2.5rem);
    /* font-size: 2.5em; */
    font-weight: 400;
}

.blogContent {
    width: 80vw;
    margin-left: .5em;
    /* letter-spacing: .1ch; */
    text-align: center;
    line-height: 1.2em;
    /* text-align: left; */
    font-size: clamp(1rem, 1.2vw, 1.5rem);


}

.dateNameWrapper {
    display: flex;
    gap: 2em;
    padding: 1em;

}




.blogImage,
.blogFirstImage {
    width: 95%;
    max-width: 500px;
    height: auto;
    /* margin: .5em */
    padding: 1em;
    margin: auto;
}

@media screen and (min-width: 950px) {


    .home {
        width: 50vw;
        margin-top: 200px;


    }

    .blogTitle {
        margin-left: .5em;

        text-align: left;

    }

    .blogContent {
        margin-left: .5em;
        letter-spacing: .1ch;
        text-align: justify;
        line-height: 1.5em;
    }

    .blogFirstImage {
        width: 100%;
        max-width: 700px;
        height: auto;
        /* margin: .5em */
        padding: 1em;
        margin: auto;
    }

}