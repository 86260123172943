@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Architects+Daughter&family=Quicksand:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%
}


body {
  height: 100%;
  margin: 0;
  font-family: Quicksand;
  /* background-color: #041C32; */
  /* background: linear-gradient(360deg, hsla(238, 53%, 33%, 1) 50%, hsla(298, 25%, 25%, 1) 100%); */
  background-image: linear-gradient(45deg,
      hsl(240deg 91% 13%) 0%,
      hsl(246deg 87% 13%) 10%,
      hsl(251deg 83% 13%) 20%,
      hsl(256deg 79% 13%) 30%,
      hsl(259deg 75% 13%) 39%,
      hsl(263deg 70% 12%) 49%,
      hsl(266deg 66% 12%) 59%,
      hsl(269deg 61% 12%) 71%,
      hsl(272deg 57% 12%) 84%,
      hsl(275deg 52% 12%) 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #FDF6FF
}



h2 {
  font-size: clamp(1.8rem, 1.9153rem + 1.6368vw, 2.6rem);

}

p {

  padding: .6em;

}

h4 {
  font-size: clamp(.4rem, 0.3153rem + 1.6368vw, 1rem);
  /* font-size: .4em; */
  font-weight: 300;
}