.headerContainer {

    overflow: hidden;
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
    width: 100vw;
}

.topWrapper {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 100vw;



}

.blogName {
    font-family: 'Amatic SC', cursive;
    font-size: 2.8rem;
    margin-left: auto;

}


.navbar {
    position: fixed;
    min-width: 100%;
    height: 100%;
    top: -100%;

    background-image: linear-gradient(45deg,
            hsl(240deg 91% 13%) 0%,
            hsl(246deg 87% 13%) 10%,
            hsl(251deg 83% 13%) 20%,
            hsl(256deg 79% 13%) 30%,
            hsl(259deg 75% 13%) 39%,
            hsl(263deg 70% 12%) 49%,
            hsl(266deg 66% 12%) 59%,
            hsl(269deg 61% 12%) 71%,
            hsl(272deg 57% 12%) 84%,
            hsl(275deg 52% 12%) 100%);
    transition: 1s ease;

}

.navbar.active {
    top: 0;
    transition: 1s ease;
    background-image: linear-gradient(45deg,
            hsl(240deg 91% 13%) 0%,
            hsl(246deg 87% 13%) 10%,
            hsl(251deg 83% 13%) 20%,
            hsl(256deg 79% 13%) 30%,
            hsl(259deg 75% 13%) 39%,
            hsl(263deg 70% 12%) 49%,
            hsl(266deg 66% 12%) 59%,
            hsl(269deg 61% 12%) 71%,
            hsl(272deg 57% 12%) 84%,
            hsl(275deg 52% 12%) 100%);

}



.links {
    display: flex;
    flex-direction: column;
    align-items: center;

}

a {
    text-decoration: none;
    padding: .5em;
    /* margin-left: 16px; */
    color: #FFF;
    /* letter-spacing: .1ch; */
    /* font-size: clamp(1rem, 0.7153rem + 1.6368vw, 1.2rem);*/
    font-size: 1.3rem;
    margin-block: 2.5rem;
}

.menuIcon {
    margin-left: auto;
    margin-top: .5em;
    margin-right: .5em;
    font-size: 2.5em;
    cursor: pointer;

}

.closed {
    cursor: pointer;
    justify-content: end;
    width: 100%;
}


.close {
    display: block;
    font-size: 2em;
    color: #FFF;

}



@media screen and (min-width: 950px) {

    .blogName {
        z-index: 999;
        font-size: 3.9rem;
        margin-bottom: .5em;
        margin-left: .9em;
        margin-top: .9em;
        padding: 0;
        /* margin: 0; */
    }


    .menu,
    .close {
        display: none;
    }

    .headerContainer {
        flex-direction: row;
        justify-content: center;
        max-width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 120px;

    }

    .navbar {

        padding: 1.5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 30%;
        margin: 0 auto;
        border-bottom: 1px solid #f2f2f2;
        /* margin-top: 2px; */
        top: 0;
        height: 15%;

        /* background-color: #033146; */



    }


    .links {

        display: flex;
        flex-direction: row;
        margin-left: auto;
        gap: 5em;
        text-align: center;
        margin-top: 0;
    }

}