.mainPostContainer {
    margin-top: 300px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.postContainer {
    /* width: 80vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.blogImage {
    width: 95%;
    max-width: 500px;
    max-height: auto;
    /* margin: .5em */
    padding: 1em;
    margin: auto;
}

.nameAndDate {
    display: flex;
    gap: 2em;
}

.date {
    text-align: start;
}

.blogTitle {
    margin-left: .5em;

    text-align: center;
    font-size: clamp(2rem, 1.9153rem + 1.6368vw, 2.5rem);
    /* font-size: 2.5em; */
    font-weight: 400;
}

.blogContent {
    width: 80vw;
    padding: 2em;
    margin-left: .5em;
    /* letter-spacing: .1ch; */
    text-align: start;
    line-height: 1.5em;
    font-size: clamp(1rem, 1.2vw, 1.5rem);


}