.container {
    max-width: 100vw;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    margin-top: 10em;
    align-items: center;
}


.container form {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    max-height: 100vh;
}


.container h2 {
    margin-bottom: 1em;
}



.container textarea {
    font-family: Quicksand;
    height: 70vh;
    width: 80vw;
    max-height: 60em;
    max-width: 50em;
}




.title,
.author,
.language,
.container textarea,
button {
    border-radius: 8px;

    font-size: .9em;
    padding: .5em;
    margin: 1em 0;
}

.author,
.language,
button {
    width: 180px;

}

.language,
button {

    font-weight: 900;
}

button {
    margin: 1em auto;

}

select {
    font-family: Quicksand;
}



input[type="file"] {
    display: none;
}

.uploadImg {
    font-size: .9em;

    border-radius: 8px;
    border: 1px solid #ccc;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    background-color: #efefef;
    color: black;
    text-align: start;
    padding: .5em;
    margin: 1em 0;
    /* margin-left: auto; */
    width: 180px;

}

.uploadImg:hover {
    background-color: white;
    color: black;
    font-weight: 900;
}

.buttonsContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

@media screen and (min-width: 950px) {
    .uploadImg {

        max-width: 30%;
        margin-left: auto;


    }


    .author,
    .language,
    button {
        max-width: 30%;

    }

    .language,
    button {
        max-width: 30%;

    }





    .container textarea {

        width: 60vw;

    }




    .buttonsContainer {
        flex-direction: row;
    }
}