.container {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    max-width: 100vw;
    /* background-color: #033146; */
    /* background-color: #041C32; */

    position: fixed;
    left: 0;
    right: 0;
    height: 120px;
}


.navbar {

    padding: 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid #f2f2f2;
    margin-top: 2px;
    /* background-color: #064663; */
    /* background-color: #033146; */

    background-color: #041C32;

}

/* main {
    width: 80vw
} */

/* .mainDisplay {
    display: flex;
    margin-top: 145px
} */

.links {
    margin-left: auto;
}

a {
    text-decoration: none;
    padding: .5em;
    margin-left: 16px;
    color: #FFFADE;
    font-size: 1.2rem;
    letter-spacing: .1ch;
    font-size: clamp(1rem, 0.7153rem + 1.6368vw, 1.2rem);
}

a:hover {
    color: white;
    font-weight: bold;
}


.border {
    height: 80px;

}